let apiRoot;
let contentApiRoot;
let contentSnippetParams;
let healthieSSOLink;
let legacyAppRoot;
let myAppRoot;
let patient_portal;
let publicAppRoot;
let pwnConsultRoot;
let thirdPartyAppRoot;
let wwwAppRoot;

const ENVIRONMENTS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  QA: 'qa',
  PREVIEW: 'preview',
  DEV: 'dev',
  LOCAL: 'local',
  TEST: 'test',
};

switch (process.env.REACT_APP_ENV) {
  case ENVIRONMENTS.PRODUCTION:
    apiRoot = 'https://secure.everlywell.com/aapi';
    contentApiRoot = 'https://content-api.everlywell.com/contentable';
    contentSnippetParams =
      'snippets?include-children=true&versions=2&published=true';
    legacyAppRoot = 'https://secure.everlywell.com';
    myAppRoot = 'https://results.everlywell.com';
    publicAppRoot = 'https://www.everlywell.com';
    pwnConsultRoot = 'https://th.pwnhealth.com';
    thirdPartyAppRoot = 'https://humana.everlywell.com';
    wwwAppRoot = 'https://www.everlywell.com';
    healthieSSOLink =
      'https://telehealth.everlywell.com/users/sign_in/everlywell';
    patient_portal = 'https://28386-1.portal.athenahealth.com/';

    break;
  case ENVIRONMENTS.STAGING:
    apiRoot = 'https://secure-staging.everlywell.com/aapi';
    contentApiRoot = 'https://content-api.everlywell.com/contentable';
    contentSnippetParams =
      'snippets?include-children=true&versions=2&published=false';
    legacyAppRoot = 'https://secure-staging.everlywell.com';
    myAppRoot = 'https://results-staging.everlywell.com';
    publicAppRoot = 'https://staging.everlywell.com';
    pwnConsultRoot = 'https://th-demo.pwnhealth.com';
    thirdPartyAppRoot = 'https://thirdpartyapp-staging.everlywell.com';
    wwwAppRoot = 'https://staging.everlywell.com';
    healthieSSOLink =
      'https://securestaging.gethealthie.com/users/sign_in/everlywell';
    patient_portal = 'https://28386-01.portal.athenahealth.com/';
    break;
  case ENVIRONMENTS.PREVIEW:
    apiRoot = 'https://v1-2-145.secure-gitlab-preview.everlywell.com/aapi';
    contentApiRoot = 'https://content-api-qa.everlywell.com/contentable';
    contentSnippetParams =
      'snippets?include-children=true&versions=2&published=false';
    legacyAppRoot = 'https://v1-2-145.secure-gitlab-preview.everlywell.com';
    myAppRoot = 'https://v1-2-145.results-preview.everlywell.com';
    publicAppRoot = 'https://v1-2-145.public-preview.everlywell.com';
    pwnConsultRoot = 'https://th-demo.pwnhealth.com';
    thirdPartyAppRoot = 'https://thirdpartyapp-staging.everlywell.com';
    wwwAppRoot = 'https://v1-2-145.public-preview.everlywell.com';
    healthieSSOLink =
      'https://securestaging.gethealthie.com/users/sign_in/everlywell';
    patient_portal = 'https://28386-01.portal.athenahealth.com/';
    break;
  case ENVIRONMENTS.QA:
    apiRoot = 'https://secure-qa.everlywell.com/aapi';
    contentApiRoot = 'https://content-api-qa.everlywell.com/contentable';
    contentSnippetParams =
      'snippets?include-children=true&versions=2&published=false';
    legacyAppRoot = 'https://secure-qa.everlywell.com';
    myAppRoot = 'https://results-qa.everlywell.com';
    publicAppRoot = 'https://qa.everlywell.com';
    pwnConsultRoot = 'https://th-demo.pwnhealth.com';
    thirdPartyAppRoot = 'https://thirdpartyapp-qa.everlywell.com';
    wwwAppRoot = 'https://qa.everlywell.com';
    healthieSSOLink =
      'https://securestaging.gethealthie.com/users/sign_in/everlywell';
    patient_portal = 'https://28386-01.portal.athenahealth.com/';
    break;
  case ENVIRONMENTS.DEV:
    apiRoot = 'https://secure-dev.everlywell.com/aapi';
    contentApiRoot = 'https://content-api-qa.everlywell.com/contentable';
    contentSnippetParams =
      'snippets?include-children=true&versions=2&published=false';
    legacyAppRoot = 'https://secure-dev.everlywell.com';
    myAppRoot = 'https://results-dev.everlywell.com';
    publicAppRoot = 'https://dev.everlywell.com';
    pwnConsultRoot = 'https://th-demo.pwnhealth.com';
    thirdPartyAppRoot = 'https://thirdpartyapp-qa.everlywell.com';
    wwwAppRoot = 'https://dev.everlywell.com';
    healthieSSOLink =
      'https://securestaging.gethealthie.com/users/sign_in/everlywell';
    patient_portal = 'https://28386-01.portal.athenahealth.com/';
    break;
  case ENVIRONMENTS.LOCAL:
    apiRoot = process.env.API_ROOT || 'http://localhost:3000/aapi';
    contentApiRoot =
      process.env.CONTENT_API_ROOT || 'http://localhost:3002/contentable';
    contentSnippetParams =
      'snippets?include-children=true&versions=2&published=false';
    legacyAppRoot = process.env.LEGACY_APP_ROOT || 'http://localhost:3000';
    myAppRoot = process.env.MY_APP_ROOT || 'http://localhost:3001';
    publicAppRoot = process.env.PUBLIC_APP_ROOT || 'http://localhost:4567';
    pwnConsultRoot = 'https://th-demo.pwnhealth.com';
    thirdPartyAppRoot =
      process.env.THIRD_PARTY_APP_ROOT || 'http://localhost:3007';
    wwwAppRoot = process.env.WWW_APP_ROOT || 'http://localhost:8080';
    healthieSSOLink =
      'https://securestaging.gethealthie.com/users/sign_in/everlywell';
    patient_portal = 'https://28386-01.portal.athenahealth.com/';
    break;
  default:
    apiRoot = 'https://secure-qa.everlywell.com/aapi';
    contentApiRoot = 'https://content-api-qa.everlywell.com/contentable';
    contentSnippetParams =
      'snippets?include-children=true&versions=2&published=false';
    legacyAppRoot = 'https://secure-qa.everlywell.com';
    myAppRoot = 'http://localhost:3001';
    publicAppRoot = 'http://localhost:4567';
    thirdPartyAppRoot = 'https://thirdpartyapp-qa.everlywell.com';
    wwwAppRoot = 'http://localhost:8080';
    healthieSSOLink =
      'https://securestaging.gethealthie.com/users/sign_in/everlywell';
    patient_portal = 'https://28386-01.portal.athenahealth.com/';
}

if (process.env.NODE_ENV === ENVIRONMENTS.TEST) {
  apiRoot = 'https://www.everlywell.com/aapi';
  contentApiRoot = 'https://content-api.everlywell.com/contentable';
  contentSnippetParams =
    'snippets?include-children=true&versions=2&published=true';
  legacyAppRoot = 'https://www.everlywell.com';
  myAppRoot = 'https://results.everlywell.com';
  publicAppRoot = 'https://www.everlywell.com';
  thirdPartyAppRoot = 'https://humana.everlywell.com';
  wwwAppRoot = 'https://www.everlywell.com';
}

const ANCESTRY_LOGIN_URL = `https://www.ancestry.com/account/signin`;
const API_ROOT = apiRoot;
const CONTENT_API_ROOT = contentApiRoot;
const CONTENT_SNIPPET_PARAMS = contentSnippetParams;
const EVERLYWELL_SAMPLE_COLLECTION = `https://support.everlywell.com/category/9-collecting-your-sample`;
const EVERLYWELL_SUPPORT_ROOT = `https://support.everlywell.com`;
const LEGACY_APP_ROOT = legacyAppRoot;
const MY_APP_ROOT = myAppRoot;
const PUBLIC_APP_ROOT = publicAppRoot;
const PWN_CONSULT_ROOT = pwnConsultRoot;
const THIRD_PARTY_APP_ROOT = thirdPartyAppRoot;
const WWW_APP_ROOT = wwwAppRoot;
const VERSION = 'v2';
const HEALTH_PROFILE_API = `health-profile/api/${VERSION}`;
const MEMBER_SUPPORT_URL = `https://everlywell.force.com/membersupport/s/`;
const HEALTHIE_SSO_LINK = `${legacyAppRoot}/telehealth/sessions/new`;
const TELEHEALTH_INTERSTITIAL_PAGE_URL = `${myAppRoot}/telehealth/sessions`;
const HEALTHIE_WHITE_LABEL_SSO_LINK = healthieSSOLink;
const QUEST_TEST_DIRECTORY_URL =
  'https://testdirectory.questdiagnostics.com/test';

const ANCESTRY_URLS = {
  0: ANCESTRY_LOGIN_URL,
  86: 'https://www.ancestryhealth.com/reports/Vitamin-D',
  87: 'https://www.ancestryhealth.com/reports/Familial-Hypercholesterolemia',
  88: 'https://www.ancestryhealth.com/reports/Vitamin-B12',
};

const DEFAULT_BOX_IMAGE_URL =
  'https://ucarecdn.com/db28835d-a7bc-417c-b00c-0b19415bf926/straightlayingdownAtHomeLabTestTransparentsaddadsasdasda.png';

const DOWNLOAD_APP_ON_APP_STORE_URL =
  'https://apps.apple.com/app/apple-store/id1614888856?pt=124169076&ct=campaign%201&mt=8';

const PATIENT_PORTAL = patient_portal;

module.exports = {
  ANCESTRY_LOGIN_URL,
  ANCESTRY_URLS,
  API_ROOT,
  CONTENT_API_ROOT,
  CONTENT_SNIPPET_PARAMS,
  DEFAULT_BOX_IMAGE_URL,
  DOWNLOAD_APP_ON_APP_STORE_URL,
  ENVIRONMENTS,
  EVERLYWELL_SAMPLE_COLLECTION,
  EVERLYWELL_SUPPORT_ROOT,
  HEALTH_PROFILE_API,
  LEGACY_APP_ROOT,
  MY_APP_ROOT,
  PUBLIC_APP_ROOT,
  PWN_CONSULT_ROOT,
  THIRD_PARTY_APP_ROOT,
  WWW_APP_ROOT,
  MEMBER_SUPPORT_URL,
  HEALTHIE_SSO_LINK,
  HEALTHIE_WHITE_LABEL_SSO_LINK,
  QUEST_TEST_DIRECTORY_URL,
  TELEHEALTH_INTERSTITIAL_PAGE_URL,
  PATIENT_PORTAL,
};
