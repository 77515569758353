import {
  API_ROOT,
  CONTENT_API_ROOT,
  CONTENT_SNIPPET_PARAMS,
  ENVIRONMENTS,
  EVERLYWELL_SUPPORT_ROOT,
  LEGACY_APP_ROOT,
  MY_APP_ROOT,
  PUBLIC_APP_ROOT,
  THIRD_PARTY_APP_ROOT,
  WWW_APP_ROOT,
} from './constants/urls';

const FACEBOOK_URL = 'https://www.facebook.com/groups/everlywell/';
const STORE_URL = 'https://www.everlywell.com/products';
const REFERRAL_URL = 'https://www.everlywell.com/referral';
const SCIENCE_URL = 'https://www.everlywell.com/science';
const REGISTRATION_URL = 'https://www.everlywell.com/register';
const SAMPLE_COLLECTION_TIPS =
  'https://support.everlywell.com/category/9-category';
const HIPPA_URL = 'https://www.everlywell.com/hipaa-and-telehealth-consent';
const PRODUCT_URL = 'https://www.everlywell.com/product-consent';
const EH_ROOT = 'https://www.everlyhealth.com/';
const EHS_ROOT = 'https://www.everlyhealthsolutions.com/';

const COLLECTION_VIDEO_URLS = {
  aptima_urine: 'https://player.vimeo.com/video/731852590?h=cdb46bd11c',
  blood_adx100: 'https://player.vimeo.com/video/302930236',
  blood_dbs_ii: 'https://player.vimeo.com/video/368341287',
  blood_double_strip_adx:
    'https://player.vimeo.com/video/713004093?h=285e448506',
  blood: 'https://player.vimeo.com/video/368341287',
  cassette: 'https://player.vimeo.com/video/613998457?h=890201950e',
  cobas_urine: 'https://player.vimeo.com/video/305839978',
  default: 'https://support.everlywell.com/category/9-collecting-your-sample',
  dried_urine: 'https://player.vimeo.com/video/305839942',
  nasal_swab: 'https://player.vimeo.com/video/399342549',
  nasopharyngeal_swab: 'https://player.vimeo.com/video/399342549',
  saliva_no_identifiers: 'https://player.vimeo.com/video/500171498',
  saliva: 'https://player.vimeo.com/video/305839966',
  vaginal_swab: 'https://player.vimeo.com/video/305839950',
};

const COLLECTION_HELP_EMAIL = 'contact@everlywell.com';

const STATUSES = {
  RESULTS_APPROVED: 'ready',
  IN_PROGRESS: [
    'processing',
    'registered',
    'canceled',
    'in_transit',
    'delivered',
    'lab_visit_approved',
  ],
  RESULTS_PROCESSING: 'processing',
  IN_TRANSIT: 'in_transit',
  DELIVERED: 'delivered',
  KIT_REGISTERED: 'registered',
  RESULTS_VIEWABLE: ['ready', 'results_ready', 'results_approved'],
  KIT_CANCELED: 'canceled',
  GENOMICS_APPROVED: 'approved',
  LAB_VISIT_APPROVED: 'lab_visit_approved',
};

const API_ERRORS = {
  kitRegistration: {
    DISCONTINUED_KIT:
      'The kit you are trying to register is no longer able to be processed by the lab.',
    EXPIRED_KIT: 'The kit you are trying to register is expired. We are happy to send you a replacement kit so that you can receive results.',
    CONTACT_CUSTOMER_CARE: 'Please reach out to customer care for a replacement kit.',
    NETWORK_ERROR: {
      value: 'TypeError: Failed to fetch',
      display:
        'There was an error submitting your Kit ID. Please try again or contact Customer Experience',
    },
    UNEXPECTED_ERROR:
      "We're sorry, there was an unexpected error, please try again or contact Customer Experience",
    ADDRESS_VALIDATION_ERRORS: [
      'Please verify your zip code',
      'Unable to verify address.',
    ],
    ZIP_CODE_ERROR: 'Please verify your zip code',
    MINIMUM_AGE_ERROR: {
      value: 'Birthday is invalid.',
      display: `We are sorry for the inconvenience, but the date of birth you entered doesn't meet the age requirement for this test. Click <a href="https://support.everlywell.com/article/48-are-everlywell-tests-suitable-for-children" target="_blank">here</a> to learn more about our age-restriction policy.`,
    },
  },
};

const ACCOUNT_SETTINGS = '/v1/users';
const ALL_RESULTS_URL = '/v2/results_dashboard';
const APP_ENV = process.env.REACT_APP_ENV;
const APPROVED_RESULTS_URL = '/kit_results?status=results_approved';
const AUTH_BY_TOKEN_URL = '/v1/authentications/token';
const BILLING_AGREEMENT_TOKEN_URL = `${LEGACY_APP_ROOT}/paypal/billing_agreement.json`;
const BILLING_AGREEMENT_URL = `${LEGACY_APP_ROOT}/paypal/billing_agreement/execute.json`;
const { CONTENTFUL_PRODUCTS_SPACE_ID, CONTENTFUL_PUBLIC_SPACE_ID } =
  process.env;
const { CONTENTFUL_PRODUCTS_ACCESS_TOKEN, CONTENTFUL_PUBLIC_ACCESS_TOKEN } =
  process.env;
const { CONTENTFUL_ENV } = process.env;
const ERROR_URL = '/v1/errors';
const ITERABLE_URL = process.env.REACT_APP_ITERABLE_URL;
const ITERABLE_TOKEN = process.env.REACT_APP_ITERABLE_TOKEN;
const MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY;
const ENTERPRISE_MIXPANEL_API_KEY =
  process.env.REACT_APP_ENTERPRISE_MIXPANEL_API_KEY;
const PRODUCTS_INDEX_ROUTE = '/products';
const REGISTRATION_ROUTE = '/register';
const RESULT_URL = '/v1/results';
const SHARE_URL = '/share/';
const USERS_BASE_URL = '/v1/users';
const VERSION = process.env.REACT_APP_VERSION;
const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
const STORE_BASIC_AUTH_TOKEN = process.env.STORE_BASIC_AUTH_TOKEN;

const TEST_SLUGS: { [key: string]: string } = {
  FOOD_ALLERGY: 'food-allergy-test',
  INDOOR_OUTDOOR_ALLERGY: 'indoor-outdoor-allergy-test',
};

const CLASS_STYLE_CHART_FF: { [key: string]: string } = {
  'food-sensitivity': 'enableFoodSensitivityClassStyleChart',
  'indoor-outdoor-allergy-test': 'enableIndoorOutdoorAllergyClassStyleChart',
};

const GROUPING_METHODS = {
  CATEGORY: 'category',
  FOOD: 'food',
  REACTIVITY: 'reactivity',
};

const MARKER_TYPES = {
  INTENSITY: 'intensity',
  FOOD_SENSITIVITY: 'food-sensitivity',
  FOOD_SENSITIVITY_PKI: 'food-sensitivity-test',
  FOOD_SENSITIVITY_EXPANSION_PKI: 'food-sensitivity-expansion-test',
  FOOD_SENSITIVITY_COMPREHENSIVE_PKI: 'food-sensitivity-comprehensive-test',
  FOOD_SENSITIVITY_NEXUS: 'food-sensitivity-nexus',
  NUMERICAL: 'numerical',
  MULTI_NUMERICAL: 'multi-numerical',
  DESCRIPTIVE: 'descriptive',
  PLAIN_NUMBER: 'plain-number',
};

const RGB_DICTIONARY: { [key: string]: string } = {
  blue: '170, 214, 243',
  gray: '204,204,204', // no def for this in DLS. But there is a setting for 'gray' in spree. So hardcoding this for now until we can decide on a fix for this
  green: '190, 234, 185',
  red: '245, 149, 150',
  yellow: '248, 215, 140',
  default: '248, 215, 140',
};

const KIT_TEST_IDS = {
  FOOD_SENSITIVITY: [3, 76, 83, 89, 90, 214, 228],
  NUMERICAL: [41, 44, 47],
  DESCRIPTIVE: [45, 54, 55, 69, 70, 71, 72, 73, 74, 78, 82],
};

const FS_LAB: { [key: string]: string } = {
  'indoor-outdoor-allergies': 'PKI',
  'food-sensitivity-test': 'PKI',
  'food-sensitivity-expansion-test': 'PKI',
  'food-sensitivity-comprehensive-test': 'PKI',
  'food-sensitivity': 'PKI',
  'food-sensitivity-expansion': 'ALLETESS',
};

// These are the IDs of the tests defined by slug in CONSULT_GROUPS :diagnostic_consult_blacklist
const CHAT_BLACKLIST_TEST_IDS = [
  2, //  Women's Health
  36, // cardiovascular-cm
  37, // inflammation
  40, // metabolism-cm
  42, // heavy-metals-and-elements-test
  45, // comprehensive std test
  46, // cardiovascular
  50, // womens-fertility-flo-living
  51, // hba1c-test
  54, // Sexual Health Test - Male
  55, // Sexual Health Test - Female
  57, // PrEP Basic Test
  58, // PrEP Comprehensive Test
  62, // PrEP Basic
  63, // PrEP Basic
  69, // Chlamydia & Gonorrhea Test
  70, // Hepatitis C Test
  71, // HIV Test
  72, // Syphilis Test
  73, // Trichomoniasis Test
  74, // HPV Test - Female
  75, // vitamin-d-and-inflammation-test-pre-registered
  77, // hba1c-test-medicare-advantage
  78, // fit-test-medicare-advantage
  81, // Comprehensive Vitamin Test
  82, // Lyme Disease Test
];

const DATE_FORMAT_SHORT = `MMMM d`;

const SHIPPING_ETA = {
  MIN: 2,
  MAX: 3,
};

enum FS_SEVERITIES {
  LOW = 1,
  MILD = 2,
  MODERATE = 3,
  HIGH = 4,
}

enum INTENSITY_SEVERITIES {
  VERY_LOW = 1,
  LOW = 5,
  MODERATE = 2,
  HIGH = 3,
}

enum NUMERICAL_SEVERITIES {
  NORMAL = 1,
  LOW = 2,
  HIGH = 3,
}

enum DESCRIPTIVE_SEVERITIES {
  NOT_DETECTED = 1,
  EQUIVOCAL_OR_INDETERMINATE = 2,
  DETECTED = 3,
  INVALID_OR_REJECTED = 4,
}

const SEVERITY_DESCRIPTORS = {
  HIGH: 'high',
  LOW: 'low',
  NORMAL: 'normal',
  HIGH_REACTIVITY: 'high reactivity',
  MODERATE_REACTIVITY: 'moderate reactivity',
  MILD_REACTIVITY: 'mild reactivity',
  NORMAL_REACTIVITY: 'normal reactivity',
  DETECTED: 'detected',
  NOT_DETECTED: 'not detected',
  EQUIVOCAL: 'equivocal',
  INDETERMINATE: 'indeterminate',
  INVALID: 'invalid',
  REJECTED: 'specimen rejected',
  ACCEPTABLE: 'acceptable',
  ELEVATED: 'elevated',
  OPTIMAL: 'optimal',
  SUBOPTIMAL: 'suboptimal',
};

const RESULT_SECTIONS_NAMES = {
  RESULTS: 'Results',
  NEXT_STEPS: 'Next Steps',
  RESOURCES: 'Resources',
  HELP: 'Help',
};

const MAX_RESULT_SECTIONS_COUNT_TO_SCROLL = 3;

const SCREEN = 'Screen';

const LESS_THAN_DETECTABLE_LIMIT = '<dl';
const NOT_APPLICABLE = 'n/a';
const NOTAPPLICABLE = 'na';
const DNR = 'dnr';
const NSA = 'nsa';
const QNS = 'qns';
const NCAL = 'ncal';
const INVALID = 'invalid';
const MARKER_VALUE_EXCEPTIONS = [
  LESS_THAN_DETECTABLE_LIMIT,
  NOT_APPLICABLE,
  NOTAPPLICABLE,
  DNR,
  NSA,
  QNS,
  NCAL,
  INVALID,
];

const SCROLL_TO_FS_PERSONALIZATION = 'fsPersonalization';
const SCROLL_TO_RESULTS = 'scrollToResults';
const RESULTS_DISCUSSION = 'results-discussion-section';
const SCROLL_TO_TELEHEALTH = 'telehealth';
const SCROLL_TO_PAYMENT_SETTINGS = 'paymentSettings';
const HEADER_HEIGHT = 152;

const SEVERITY_VALUE = {
  NOT_DETECTED: 1,
  INDETERMINATE: 2,
  DETECTED: 3,
  OTHER: 4,
};

const TEST_IDS = {
  B_VITAMINS: 80,
  BREAST_MILK_DHA: 48,
  CHLAMYDIA_GONORRHEA: 69,
  CHOLESTEROL_LIPIDS: 65,
  CHOLESTEROL_LIPIDS_AYUMETRIX: 46,
  CHOLESTEROL_LIPIDS_COBALT: 95,
  CHOLESTEROL_LIPIDS_CORE_MEDICA: 36,
  COMPREHENSIVE_VITAMIN: 81,
  COVID_19_ASSURANCE_AUTOREG: 159,
  COVID_19_ASSURANCE_DTC: 120,
  COVID_19_ASSURANCE_HCP: 117,
  COVID_19_BIOCERNA: 115,
  COVID_19_BIOREF: 114,
  COVID_19_COMPASS: 121,
  COVID_19_EXACT_SCIENCES: 143,
  COVID_19_GRAVITY_DTC: 119,
  COVID_19_GRAVITY_HCP: 118,
  COVID_19_IGENOMIX: 146,
  COVID_19_MEDICAL_HCP: 126,
  COVID_19_METRIC_HCP: 125,
  COVID_19_MTL: 113,
  COVID_19_P23_DTC: 136,
  COVID_19_P23_HCP: 116,
  COVID_19_PKI_DTC: 128,
  COVID_19_PKI_HCP: 127,
  COVID_19_FULGENT_DTC: 129,
  COVID_19_FULGENT_HCP: 124,
  COVID_19_GRAVITY_DTC_OTHER: 164,
  COVID_19_ASSURANCE_DTC_OTHER: 163,
  FIT_COBALT_MEDICARE_ADVANTAGE: 104,
  FIT_COLORECTAL_CANCER: 0,
  FIT_MEDICARE_ADVANTAGE: 78,
  FOLIC_ACID: 79,
  FOOD_SENSITIVITY: 3,
  FOOD_SENSITIVITY_PKI: 83,
  FOOD_SENSITIVITY_EXPANSION: 76,
  FOOD_SENSITIVITY_EXPANSION_PKI: 89,
  FOOD_SENSITIVITY_COMPREHENSIVE_PKI: 90,
  FOOD_SENSITIVITY_NEXUS: 214,
  FOOD_SENSITIVITY_COMPREHENSIVE_NEXUS: 228,
  HBA1C: 66,
  HBA1C_AYUMETRIX: 51,
  HBA1C_COBALT: 91,
  HBA1C_COBALT_MEDICARE_ADVANTAGE: 106,
  HBA1C_COBALT_MEDICARE_ADVANTAGE_DBS: 112,
  HBA1C_MEDICARE_ADVANTAGE: 77,
  HEAVY_METALS: 1,
  HEAVY_METALS_ELEMENTS: 42,
  HEART_HEALTH: 64,
  HEART_HEALTH_COBALT: 94,
  HEPATITIS_C: 70,
  HIV: 71,
  HPV_FEMALE: 74,
  INDOOR_OUTDOOR_ALLERGY: 111,
  LYME_DISEASE: 82,
  MENS_HEALTH: 43,
  METABOLISM: 47,
  METABOLISM_COBALT: 96,
  METABOLISM_CORE_MEDICA: 40,
  METABOLISM_EVERLYWELL: 96,
  MICROALBUMIN_COBALT: 84,
  MICROALBUMIN_COBALT_MEDICARE_ADVANTAGE: 105,
  MICROALBUMIN_MEDICARE_ADVANTAGE: 85,
  OMEGA3_BASIC: 67,
  OMEGA3_PLUS: 68,
  OVARIAN_RESERVE: 56,
  OVARIAN_RESERVE_COBALT: 108,
  PERIMENOPAUSE: 59,
  PERIMENOPAUSE_COBALT: 110,
  POSTMENOPAUSE: 60,
  POSTMENOPAUSE_EVERLYWELL: 122,
  SEXUAL_HEALTH: 45,
  SLEEP_STRESS: 39,
  STD_FEMALE: 55,
  STD_MALE: 54,
  SYPHILLIS: 72,
  TESTOSTERONE: 44,
  TESTOSTERONE_EVERLYWELL: 103,
  THYROID: 41,
  TRICHOMONIASIS: 73,
  VITAMIN_D: 49,
  VITAMIN_D_EVERLYWELL: 101,
  VITAMIN_D_INFLAMMATION: 53,
  VITAMIN_D_INFLAMMATION_CORE_MEDICA: 37,
  VITAMIN_D_INFLAMMATION_EVERLYWELL: 100,
  WOMENS_HEALTH: 2,
  WOMENS_HEALTH_FERTILITY: 50,
  WOMENS_FERTILITY: 61,
  VITAMIN_D_ANCESTRY: 86,
  CHOLESTEROL_LIPIDS_ANCESTRY: 87,
  B_VITAMINS_ANCESTRY: 88,
};

const TOTAL_ABNORMAL_DESCRIPTOR = {
  [MARKER_TYPES.INTENSITY]: 'allergen',
  [MARKER_TYPES.FOOD_SENSITIVITY]: 'food',
  [MARKER_TYPES.FOOD_SENSITIVITY_PKI]: 'food',
  [MARKER_TYPES.FOOD_SENSITIVITY_EXPANSION_PKI]: 'food',
  [MARKER_TYPES.FOOD_SENSITIVITY_COMPREHENSIVE_PKI]: 'food',
  [MARKER_TYPES.FOOD_SENSITIVITY_NEXUS]: 'food',
  [MARKER_TYPES.NUMERICAL]: 'marker',
  [MARKER_TYPES.MULTI_NUMERICAL]: 'marker',
  [MARKER_TYPES.DESCRIPTIVE]: 'results',
};

const COBRANDED_BANNER_COLORS = {
  [TEST_IDS.B_VITAMINS_ANCESTRY]: 'rgba(238,247,253)', // B Vitamins
  [TEST_IDS.CHOLESTEROL_LIPIDS_ANCESTRY]: 'rgb(248, 230, 230)', // Cholesterol & Lipids
  [TEST_IDS.VITAMIN_D_ANCESTRY]: 'rgb(246, 243, 233)', // Vitamin D
};

const ANCESTRY_TEST_IDS = [
  TEST_IDS.VITAMIN_D_ANCESTRY,
  TEST_IDS.B_VITAMINS_ANCESTRY,
  TEST_IDS.CHOLESTEROL_LIPIDS_ANCESTRY,
];

const COVID_19_IDS = [
  TEST_IDS.COVID_19_ASSURANCE_AUTOREG,
  TEST_IDS.COVID_19_ASSURANCE_DTC,
  TEST_IDS.COVID_19_ASSURANCE_HCP,
  TEST_IDS.COVID_19_BIOCERNA,
  TEST_IDS.COVID_19_BIOREF,
  TEST_IDS.COVID_19_COMPASS,
  TEST_IDS.COVID_19_EXACT_SCIENCES,
  TEST_IDS.COVID_19_GRAVITY_DTC,
  TEST_IDS.COVID_19_GRAVITY_HCP,
  TEST_IDS.COVID_19_IGENOMIX,
  TEST_IDS.COVID_19_MEDICAL_HCP,
  TEST_IDS.COVID_19_METRIC_HCP,
  TEST_IDS.COVID_19_MTL,
  TEST_IDS.COVID_19_P23_DTC,
  TEST_IDS.COVID_19_P23_HCP,
  TEST_IDS.COVID_19_PKI_DTC,
  TEST_IDS.COVID_19_PKI_HCP,
  TEST_IDS.COVID_19_FULGENT_DTC,
  TEST_IDS.COVID_19_FULGENT_HCP,
  TEST_IDS.COVID_19_GRAVITY_DTC_OTHER,
  TEST_IDS.COVID_19_ASSURANCE_DTC_OTHER,
];

const JWT_KEY = 'jwtToken';

const ELEMENT_TYPE = {
  DIV: HTMLDivElement,
  LINK: HTMLLinkElement,
};

const HOLIDAYS = [
  new Date(2021, 10, 25), // Thanksgiving,
  new Date(2021, 11, 25), // Christmas Day
  new Date(2022, 0, 1), // New Years Day
  new Date(2022, 10, 24),
  new Date(2022, 11, 25),
  new Date(2023, 0, 1),
  new Date(2023, 10, 23),
  new Date(2023, 11, 25),
  new Date(2024, 0, 1),
  new Date(2024, 10, 28),
  new Date(2024, 11, 25),
  new Date(2025, 0, 1),
  new Date(2025, 10, 27),
  new Date(2025, 11, 25),
  new Date(2026, 0, 1),
  new Date(2026, 10, 26),
  new Date(2026, 11, 25),
];

const FS_PERSONALIZATION_CONDITIONS = {
  CELIAC_DISEASE: 'Celiac Disease',
  FOOD_ALLERGY: 'Food Allergy',
  LACTOSE_INTOLERANCE: 'Lactose Intolerance',
};

const HBA1C_SKUS = [
  'hba1c',
  'hba1c-test-crl-master',
  'hba1c-humana-replacement-kit-new-york-master',
  'hba1c-humana-replacement-kit-master',
  'hba1c-test-medicare-advantage-hba1c-medicare-advantage-crl-master',
  'hba1c-test-medicare-advantage-cobalt-dbs-master',
  'hba1c-test-master',
  'hba1c-ussl-master',
  'hba1c-test-healpros-master',
  'hba1c-test-results-product-master',
  'hba1c-test-bcbs-master',
  'hba1c-test-auto-reg-master',
  'hba1c-test-medicare-advantage-master',
  'bagged-hba1c-test',
  'bagged-HbA1c',
];

const MICROALBUMIN_SKUS = [
  'microalbumin-test',
  'microalbumin-test-medicare-advantage-cobalt-master',
  'microalbumin-humana-replacement-kit-new-york-master',
  'microalbumin-test-internal',
  'microalbumin-humana-replacement-kit-master',
  'microalbumin-medicare-advantage-crl-master',
  'microalbumin-test-crl-master',
  'microalbumin-test-healpros-master',
  'microalbumin-test-bcbs-master',
  'microalbumin_test_auto_reg-master',
  'microalbumin-test-medicare-advantage-master',
  'microalbumin-test-results-product-master',
  'bagged-mau-test',
];

const FIT_SKUS = [
  'fit-test-medicare-advantage-fit-medicare-advantage-crl-master',
  'fit-colon-cancer-screening-test',
  'fit-test-bcbs-master',
  'fit-test-crl-master',
  'fit-cobalt',
  'fit-test-medicare-advantage-cobalt-master',
  'fit-test-medicare-advantage-master',
  'fit-humana-replacement-kit-master',
  'fit-test-healpros-master',
  'fit-test-auto-reg-master',
  'fit-humana-replacement-kit-new-york-master',
  'fit-polymedco-master',
  'fit-test-results-product-master',
  'bagged-fit-colon-cancer-screening-test',
];

const EGFR_SKUS = ['egfr-test', 'bagged-eGFR', 'bagged-egfr-test'];

type AttributeCollectionItem = {
  program: string;
  minutes: number;
};

type SectionsCollectionItem = {
  headline: string;
};

export type Article = {
  title: string;
  attributesCollection: {
    items: AttributeCollectionItem[];
  };
  heroSection: {
    mobileImage: {
      url: string;
    };
  };
  sectionsCollection: {
    items: SectionsCollectionItem[];
  };
};

const LOCALIZE_SKUS = [
  ...HBA1C_SKUS,
  ...MICROALBUMIN_SKUS,
  ...FIT_SKUS,
  ...EGFR_SKUS,
  'bagged-uACR',
  'wellness-test',
];

export {
  ACCOUNT_SETTINGS,
  ALL_RESULTS_URL,
  ANCESTRY_TEST_IDS,
  API_ERRORS,
  API_ROOT,
  APP_ENV,
  APPROVED_RESULTS_URL,
  AUTH_BY_TOKEN_URL,
  BILLING_AGREEMENT_TOKEN_URL,
  BILLING_AGREEMENT_URL,
  CHAT_BLACKLIST_TEST_IDS,
  CLASS_STYLE_CHART_FF,
  COBRANDED_BANNER_COLORS,
  COLLECTION_HELP_EMAIL,
  COLLECTION_VIDEO_URLS,
  RGB_DICTIONARY,
  CONTENT_API_ROOT,
  CONTENT_SNIPPET_PARAMS,
  CONTENTFUL_ENV,
  CONTENTFUL_PRODUCTS_ACCESS_TOKEN,
  CONTENTFUL_PUBLIC_ACCESS_TOKEN,
  CONTENTFUL_PRODUCTS_SPACE_ID,
  CONTENTFUL_PUBLIC_SPACE_ID,
  COVID_19_IDS,
  DATE_FORMAT_SHORT,
  DESCRIPTIVE_SEVERITIES,
  DNR,
  ELEMENT_TYPE,
  ENVIRONMENTS,
  ERROR_URL,
  EVERLYWELL_SUPPORT_ROOT,
  FACEBOOK_URL,
  FS_LAB,
  FS_PERSONALIZATION_CONDITIONS,
  FS_SEVERITIES,
  GROUPING_METHODS,
  HEADER_HEIGHT,
  HIPPA_URL,
  HOLIDAYS,
  INVALID,
  INTENSITY_SEVERITIES,
  ITERABLE_TOKEN,
  ITERABLE_URL,
  JWT_KEY,
  KIT_TEST_IDS,
  LEGACY_APP_ROOT,
  LESS_THAN_DETECTABLE_LIMIT,
  LOCALIZE_SKUS,
  MARKER_TYPES,
  MARKER_VALUE_EXCEPTIONS,
  MIXPANEL_API_KEY,
  ENTERPRISE_MIXPANEL_API_KEY,
  MY_APP_ROOT,
  NCAL,
  NOT_APPLICABLE,
  NOTAPPLICABLE,
  NSA,
  RESULT_SECTIONS_NAMES,
  MAX_RESULT_SECTIONS_COUNT_TO_SCROLL,
  NUMERICAL_SEVERITIES,
  PRODUCT_URL,
  PRODUCTS_INDEX_ROUTE,
  PUBLIC_APP_ROOT,
  QNS,
  REFERRAL_URL,
  REGISTRATION_ROUTE,
  REGISTRATION_URL,
  RESTART_ON_REMOUNT,
  RESULT_URL,
  RESULTS_DISCUSSION,
  SAMPLE_COLLECTION_TIPS,
  SCIENCE_URL,
  SCREEN,
  SCROLL_TO_FS_PERSONALIZATION,
  SCROLL_TO_RESULTS,
  SCROLL_TO_TELEHEALTH,
  SCROLL_TO_PAYMENT_SETTINGS,
  SEVERITY_DESCRIPTORS,
  SEVERITY_VALUE,
  SHARE_URL,
  SHIPPING_ETA,
  STATUSES,
  STORE_URL,
  TEST_IDS,
  TEST_SLUGS,
  THIRD_PARTY_APP_ROOT,
  TOTAL_ABNORMAL_DESCRIPTOR,
  USERS_BASE_URL,
  VERSION,
  WWW_APP_ROOT,
  EH_ROOT,
  EHS_ROOT,
  STORE_BASIC_AUTH_TOKEN,
};

export const SettingsAnchorIds = {
  payment: 'paymentSettings',
};

export enum INVISIBLE_INK_SKUS {
  FOOD_SENSITIVITY_II_PKI = 'food-sensitivity-ii-pki',
  FOOD_SENSITIVITY_COMPREHENSIVE_II_PKI = 'food-sensitivity-comprehensive-ii-pki',
  FOOD_SENSITIVITY_EXPANSION_II_PKI = 'food-sensitivity-expansion-ii-pki',
  LYME_DISEASE_II_PKI = 'lyme-disease-ii-pki',
  INDOOR_OUTDOOR_ALLERGY_II_PKI = 'indoor-outdoor-allergy-ii-pki',
}

export const invisibleInkSKUS: string[] = Object.values(INVISIBLE_INK_SKUS);

export const REGISTRATION_MODAL_ACTIONS = {
  EDIT_ADDRESS: 'Edit address',
  USE_ADDRESS: 'Use address',
  USE_SELECTED_ADDRESS: 'Use selected address',
};

export const CONTACT_CARE = {
  INITIAL_TEXT: `Contact your care team at `,
  PHONE: {
    NUMBER: `(708)–787–4438`,
    VALUE: `7087874438`,
  },
  BOTTOM_TEXT: `, Monday through Friday, 7am–7pm CST with any questions related to
          your care. If you are having serious symptoms, please call 911 or seek
          in-person care.`,
};
